<template>
  <div>
    <div class="hero-main diamond-layout white-sec" :style="style">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-6">
            <h1>The Road To <span>Financial Freedom!</span></h1>
            <p class="lead"><span class="emphasized">The Key to Financial Freedom and great wealth is a person's Ability or Skill to convert earned income into Passive Income and/or Portfolio Incom.</span> - "Robert Kiyosaki"</p>
            <div class="hero-btns">
              <a href="#distribution" class="btn el-btn">Explore</a>
            </div>
          </div>
          <div class="col-sm-12 col-md-6" data-wow-delay="0.5s">
            <div class="diamond-animation mobile-hidden">
              <div class="diamond-grid" />
              <div class="diamond-grid-2" />
              <div class="outer-Orbit">
                <div class="Orbit">
                  <div class="rotate">
                    <div class="OrbitSquare">
                      <div class="inner">A</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main">
                <div class="top-coin"><span /></div>
                <div class="lines">
                  <span class="l-1" />
                  <span class="l-2" />
                  <span class="l-3" />
                  <span class="l-4" />
                  <span class="l-5" />
                  <span class="l-6" />
                  <span class="l-7" />
                  <span class="l-8" />
                  <span class="l-9" />
                  <span class="l-10" />
                  <span class="l-11" />
                  <span class="l-12" />
                  <span class="l-13" />
                  <span class="l-14" />
                  <span class="l-15" />
                </div>
                <div class="gris2" />
              </div>
              <div class="base" />
            </div>
            <div class="mobile-visible text-center">
              <img :src="diamondAnimationMobile" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- eslint-disable -->
    <div class="coingecko-widget">
      <div class="stipe-value-slider">
        <coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,binancecoin, elrond-erd-2, maiar-dex, cardano" currency="usd" background-color="transparent" locale="en"></coingecko-coin-price-marquee-widget>
      </div>
    </div>
    <div id="distribution" class="token-sale p-tb c-l">
      <div class="container mt-6">
        <div class="text-center"><h2 class="section-heading">Treasury <span>Allocation</span></h2></div>
        <div class="text-center"><h2 class="section-heading">Initial Investment: <span>~{{ getInitialInvestment }}$</span></h2></div>
        <div class="text-center"><h2 class="section-heading">Current Value: <span>~{{ getCoinsSum }}$</span></h2></div>
        <div class="text-center">
          <h2 class="section-heading">
          Profit/Loss $:
            <span :class="`${getReturn$ > 0  ? 'profit' : 'loss'}`"><i class="positive-indication fa fa-arrow-up" /><i class="negative-indication fa fa-arrow-down" /> {{ getReturn$ }}$<span> ({{ getReturnPercentage }}%)</span></span>
          </h2>
        </div>
        <div class="sub-txt text-center">
          <p>Our treasury allocation is presented below.</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3><span class="strong">Wallet Addresses</span></h3>
            <div class="token-allocation-box row">
              <div class="wallet-container col-md-12">
                <h4>BSC/ETH</h4>
                <div class="wallet">
                  <a href="https://bscscan.com/address/0x872147d03f6887A4De6f17e15E4a21447863A7Ee" target="_blank"><span>{{ bnbwa }}</span></a>
                  <a href="" @click.prevent="onCopy(bnbwa)"><i class="fa fa-copy" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { nanoid } from 'nanoid';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';
  import diamondAnimationMobile from '@/assets/png/diamond-animation-mobile.png';
  import backgroundBanner from '@/assets/jpg/banner-1.jpg';

  export default Vue.extend({
    name: 'elros-treasury',
    data: () => ({
      coins: [],
      diamondAnimationMobile,
      initialInvestment: 0,
      bnbwa: '0x872147d03f6887A4De6f17e15E4a21447863A7Ee',
      style: { backgroundImage: `url(${backgroundBanner})` },
    }),
    computed: {
      ...mapGetters({
        getCoinsList: 'common/getCoinsList',
        getRecurringCoins: 'common/getRecurringCoins',
        getRecurringAllocation: 'common/getRecurringAllocation',
        getWa: 'session/getWa',
      }),
      getReturn$() {
        return (this.getCoinsSum - this.getInitialInvestment).toFixed();
      },
      getReturnPercentage() {
        return (((this.getCoinsSum * 100) / this.getInitialInvestment) - 100).toFixed();
      },
      getInitialInvestment() {
        const selectedWallet = this.getRecurringAllocation
          .find(({ wallet }) => wallet.toLowerCase() === this.getWa.toLowerCase());
        return selectedWallet?.initial;
      },
      getCoinsSum() {
        let sum = 0;

        this.getCoinsList.forEach((coin) => {
          const selectedCoin = this.getRecurringCoins
            .find(item => item.symbol === coin.symbol);
          const total = selectedCoin ? selectedCoin.value * coin.usd_price : 0;

          sum += total;
        });
        const selectedWallet = this.getRecurringAllocation
          .find(({ wallet }) => wallet.toLowerCase() === this.getWa.toLowerCase());

        if (selectedWallet) {
          return (sum * (selectedWallet.percentage / 100)).toFixed(0);
        }

        return sum.toFixed(2);
      },
    },
    mounted() {
      window.initTreasuryAnimation();
      window.initTreasury();
    },
    created() {
      this.fetchCoinsList();
    },
    methods: {
      ...mapActions({
        fetchCoinsList: 'common/fetchCoinsList',
      }),
      onCopy(txt) {
        copyToClipboard(txt);
        this.$toasts.toast({
          id: nanoid(),
          message: 'The link has been copied to clipboard',
          title: 'Success',
          intent: 'success',
        });
      },
    },
  });
</script>
<style>
.wallet-container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0 10px;
}

.wallet-container h4 {
  font-size: 18px;
  text-transform: uppercase;
}

.wallet-container .wallet {
  font-size: 15px;
  line-height: 1.8;
  text-transform: uppercase;
}

.wallet-container .wallet span {
  color: #eec05e;
  display: block;
  font-weight: bold;
}

@media only screen and (max-width: 1199px) {
  .wallet-container .wallet {
    font-size: 14px;
  }
}
</style>
